import { IOfficesSchedulesIndexed, IResource, ISchedule } from '@gettactic/api';
import { sortSchedulesByStart } from './sortSchedulesByStart';

interface IResourceWithSchedules extends IResource {
  schedules?: ISchedule[];
}

export function fillSchedules(
  r: IResource[] | undefined,
  usersSchedule: IOfficesSchedulesIndexed | undefined | null,
  weekDate: string
): IResourceWithSchedules[] | undefined {
  if (!usersSchedule || !r) {
    return undefined;
  }
  const day = usersSchedule.dates[weekDate] || [];
  return r.map((x) => {
    const schedules = day.filter((d) => {
      for (const s of d.slots) {
        if (s.resource.id === x.id) {
          return true;
        }
      }
      return false;
    });
    schedules.sort(sortSchedulesByStart);
    return { ...x, schedules };
  });
}
