/**
 * Uses the Intl DateTime format to check the devices
 * currently set time zone. If unknown, will return
 * undefined.
 *
 * @returns Time Zone string in IANA format
 */
export function getDeviceTZ() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
