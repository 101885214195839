import { Settings, initApi } from '@gettactic/api';
import {
  DEFAULT_EXCLUDE_SENTRY_STATUS_CODES,
  DEFAULT_TACTIC_API_URL,
  DEFAULT_TACTIC_AUTH_HEADER,
  DEFAULT_TACTIC_DEFAULT_PROTOCOL,
  DEFAULT_TACTIC_DOMAIN_AND_PORT
} from '@gettactic/api';
import * as Sentry from '@sentry/nextjs';

const defaultSettings: Settings = {
  environment:
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 'prod' : 'dev',
  slug: null,
  url: process.env.NEXT_PUBLIC_TACTIC_API_URL || DEFAULT_TACTIC_API_URL,
  debug: !!process.env.NEXT_PUBLIC_TACTIC_API_DEBUG,
  debugAuth: !!process.env.NEXT_PUBLIC_TACTIC_API_DEBUG_AUTH,
  debugAuthAccess: process.env.NEXT_PUBLIC_TACTIC_API_DEBUG_AUTH,
  debugGet: !!process.env.NEXT_PUBLIC_TACTIC_API_DEBUG_GET,
  protocol:
    process.env.NEXT_PUBLIC_TACTIC_DEFAULT_PROTOCOL ||
    DEFAULT_TACTIC_DEFAULT_PROTOCOL,
  domain:
    process.env.NEXT_PUBLIC_TACTIC_DOMAIN_AND_PORT ||
    DEFAULT_TACTIC_DOMAIN_AND_PORT,
  authHeader: DEFAULT_TACTIC_AUTH_HEADER,
  corsBypass: process.env.NEXT_PUBLIC_UNION_CORS_BYPASS
    ? process.env.NEXT_PUBLIC_UNION_CORS_BYPASS
    : '',
  unionDevAuth: process.env.NEXT_PUBLIC_UNION_DEV_AUTH || '',
  onError: (error: unknown, status?: number) => {
    if (status) {
      if (status === 401) {
        window.location.href = `${process.env.NEXT_PUBLIC_TACTIC_API_URL}/auth/login?returnUrl=${encodeURIComponent(
          `${window.location.origin}/dashboard`
        )}`;
      }
      if (
        process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' &&
        status !== 404
      ) {
        console.error('Error from api-client:', error, status);
      }

      // If not authorization or 404, send to Sentry to be logged
      if (
        !process.env.NEXT_PUBLIC_SENTRY_DISABLE &&
        !DEFAULT_EXCLUDE_SENTRY_STATUS_CODES.includes(status)
      ) {
        Sentry.captureException(error, {
          tags: {
            category: 'api-client'
          }
        });
      }
    }
  }
};

const { api, settings } = initApi(defaultSettings);
export { api, settings };
