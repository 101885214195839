import { logError } from '../errors';
import { LAST_FLOOR_STORAGE_KEY } from './defines';

export function getStorageFloor() {
  try {
    return localStorage.getItem(LAST_FLOOR_STORAGE_KEY);
  } catch (e) {
    logError(e);
  }
  return '';
}
